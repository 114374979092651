<template>
  <div class="search--msg" @click="$emit('click', data)">
    <div class="search--msg__info">
      <el-image
        style="width: 18px; height: 18px; margin-right: 10px;"
        :src="filterData.icon"
        :fit="'cover'"
      ></el-image>
      <span
        class="search--msg__info--title"
        v-html="filterData.subject"
        :title="clearHtml(filterData.subject)"
      ></span>
      <span
        class="search--msg__info--time"
        v-html="parseTime(filterData.sendTimestamp)"
      ></span>
    </div>
    <div class="search--msg__text">
      <span
        class="search--msg__text--user"
        v-if="filterData.sourceEmail"
        :title="filterData.sourceEmail"
        v-html="filterData.sourceEmail + ':'"
      ></span>
      <span
        class="search--msg__text--value"
        v-if="!!filterData.content"
        v-html="handleValue(filterData.content)"
      ></span>
    </div>
  </div>
</template>
<script>
import { parseTime } from "@/utils";
export default {
  name: "SearchEmail",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    searchKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      emailIcons: [
        {
          icon: require(`../../../../assets/images/email/treeMenu/sms.png`),
          folder_name: "INBOX",
        },
        {
          icon: require(`../../../../assets/images/email/treeMenu/send.png`),
          folder_name: "Sent",
        },
        {
          icon: require("../../../../assets/images/email/treeMenu/document.png"),
          folder_name: "Drafts",
        },
        {
          icon: require(`../../../../assets/images/email/treeMenu/trash.png`),
          folder_name: "Trash",
        },
        {
          icon: require(`../../../../assets/images/email/treeMenu/spam.png`),
          folder_name: "Spam",
        },
      ],
    };
  },
  computed: {
    filterData() {
      const folder_name = this.data.folder_name || this.data.mailboxName;
      const icon = this.emailIcons.find(
        (icon) => icon.folder_name == folder_name
      ).icon;
      const sourceEmail =
        folder_name == "Sent"
          ? this.splitTo(this.data.to)
          : this.handleKeyword(this.data.from);
      return {
        subject: this.data.subject,
        sourceEmail: sourceEmail,
        sendTimestamp: this.data.sendTimestamp,
        content: this.data.content,
        icon: icon,
      };
    },
  },
  methods: {
    splitTo(to) {
      let handlerTo;
      const tos = to.split(",").filter((item) => item.trim() != "");
      if (tos.length > 0) {
        const searchTo = tos.find((item) => item.indexOf(this.searchKey) != -1);
        if (searchTo) {
          handlerTo = this.handleKeyword(searchTo);
        } else {
          handlerTo = tos[0];
        }
      } else {
        handlerTo = this.data.from;
      }
      return handlerTo;
    },
    // 标记关键词为红色
    handleKeyword(value) {
      if (this.searchKey) {
        const searchReg = new RegExp(this.searchKey, "g");
        return value.replace(
          searchReg,
          `<span data-tag="melinkedSearch" style="color:red">${this.searchKey}</span>`
        );
      } else {
        return value;
      }
    },
    parseTime,
    handleValue(value) {
      try {
        if (value.indexOf("{") != 0) {
          throw new Error();
        }
        const qs = JSON.parse(value);
        return qs.welcomes;
      } catch (e) {
        if (value.trim() !== "") {
          let pattern = /<img\s[^>]*\/?>/g;
          value = value.replace(pattern, (match) => {
            return `[${this.$t("image")}]`;
          });
          try {
            value = this.getTextFromHtmlString(value);
          } catch (err) {}
        }
        return value;
      }
    },
    // 获取HTML字符串中文本内容
    getTextFromHtmlString(value) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, "text/html");
      let handlerStr = this.handleKeyword(doc.body.textContent);
      const sliceIndex = handlerStr.indexOf(`<span data-tag="melinkedSearch"`);
      if (sliceIndex != -1) {
        try {
          return sliceIndex > 5
            ? `...${handlerStr.slice(
                sliceIndex - Math.floor(Math.random() * 6)
              )}`
            : handlerStr.slice(sliceIndex);
        } catch (error) {
          return handlerStr;
        }
      } else {
        return handlerStr;
      }
    },
    clearHtml(text) {
      let titleHref = text;
      try {
        titleHref = titleHref
          .replace(new RegExp("<font color=red>", "g"), "")
          .replaceAll(new RegExp("</font>", "g"), "");
      } catch (error) {}
      return titleHref;
    },
  },
};
</script>
<style lang="stylus" scoped>
.search--msg
  width: 48%;
  margin: 0 1% 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  .search--msg__info
    display: flex;
    align-items: center;
    .el-avatar
      margin-right: 0.5rem;
    .search--msg__info--title
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      color: #222222;
    .search--msg__info--time
      font-size: 12px;
      color: #999;
  .search--msg__text
    font-size: 12px;
    margin: 0.2rem 0 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-flow: row;
    height: 16px;
    .search--msg__text--user
      text-decoration: underline;
      margin-right: 0.25rem;
      cursor: pointer;
      display: inline-block;
      flex-shrink: 0;
    .search--msg__text--value
      display: inline-block;
      color: #888888;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
</style>
<style lang="stylus">
@import '~styles/utils/index';
  +b(search--msg){
    +e(info){
      +m(title){
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
    }
  }
</style>
