<template>
  <div class="historyView">
    <div
      class="titleRow"
      :style="{
        'max-width': type == 'default' ? '520px' : '400px',
      }"
    >
      <i class="iconfont_Me icon-history iconStyle"></i>
      <i
        class="iconfont_Me icon-delet-fill iconStyle deleteIcon"
        @click.stop="clearAll"
      ></i>
    </div>
    <div
      class="commonRow"
      v-for="(i, index) in historyCache"
      :key="index"
      @click.stop="selectOne(i)"
      :style="{
        'max-width': type == 'default' ? '520px' : '400px',
      }"
    >
      <span class="keywordStyle">{{ i.keyword }}</span>
      <i @click.stop="deleteOne(i)" class="iconfont_Me icon-x iconStyle"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      historyCache: [],
    };
  },
  inject: ["control"],
  computed: {
    currentContact() {
      return this.control.currentContact;
    },
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
  },
  mounted() {
    document.body.addEventListener("click", this.clickoutside);
    this.init();
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.clickoutside);
    this.$store.commit("setShowSearchHistory", false);
  },
  methods: {
    init() {
      const historyStr = localStorage.getItem("searchHistoryForChat");
      if (!historyStr) {
        this.historyCache = [];
        this.$store.commit("setShowSearchHistory", false);
        return;
      }
      const historyJson = JSON.parse(this.$Base64.decode(historyStr));
      let defaultArr = [];
      switch (this.type) {
        case "default":
          defaultArr = historyJson.default || [];
          break;
        case "inner":
          if (this.currentContact.isGroup) {
            defaultArr = historyJson[`G${this.currentContact.id}`] || [];
          }
          break;
      }
      if (defaultArr.length == 0) {
        this.$store.commit("setShowSearchHistory", false);
      }
      this.historyCache = defaultArr;
    },
    clickoutside(e) {
      if (!e.target.classList.contains("el-input__inner")) {
        this.$store.commit("setShowSearchHistory", false);
      }
    },
    selectOne(item) {
      this.$store.commit("setShowSearchHistory", false);
      this.$emit("selectSearchKey", item.keyword);
    },
    deleteOne(item) {
      const historyStr = localStorage.getItem("searchHistoryForChat");
      const historyJson = JSON.parse(this.$Base64.decode(historyStr));
      let key = "";
      switch (this.type) {
        case "default":
          key = "default";
          break;
        case "inner":
          if (this.currentContact.isGroup) {
            key = `G${this.currentContact.id}`;
          }
          break;
      }
      historyJson[key] = historyJson[key].filter((i) => i.date != item.date);
      localStorage.setItem(
        "searchHistoryForChat",
        this.$Base64.encode(JSON.stringify(historyJson))
      );
      this.historyCache = historyJson[key];
    },
    async clearAll() {
      const historyStr = localStorage.getItem("searchHistoryForChat");
      const historyJson = JSON.parse(this.$Base64.decode(historyStr));
      let key = "";
      switch (this.type) {
        case "default":
          key = "default";
          break;
        case "inner":
          if (this.currentContact.isGroup) {
            key = `G${this.currentContact.id}`;
          }
          break;
      }
      historyJson[key] = [];
      localStorage.setItem(
        "searchHistoryForChat",
        this.$Base64.encode(JSON.stringify(historyJson))
      );
      this.historyCache = historyJson[key];
      await this.$nextTick();
      this.$store.commit("setShowSearchHistory", false);
    },
  },
};
</script>
<style lang="stylus" scoped>
.historyView
  width: 100%;
  height: 100%;
  overflow hidden;
  .titleRow
    height: 24px;
    padding: 5px 10px 20px 10px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .iconStyle
      font-size: 24px;
    .deleteIcon
      cursor: pointer;
      color: #B9BABF;
  .commonRow
    min-height: 24px;
    padding: 5px 10px;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background .3s;
    font-size: 14px;
    &:hover
      background: #F0F0F0;
    .keywordStyle
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
</style>
