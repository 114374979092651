<template>
  <div
    class="search--img"
    @contextmenu.prevent="$emit('contextmenuClick', $event, data)"
  >
    <el-image
      :src="formartImageUrl(data.url)"
      fit="cover"
      class="search--img__item"
      :preview-src-list="list"
      lazy
    >
      <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <div class="search--img__info">
      <span class="value" v-html="data.value"></span>
      <span
        class="name"
        v-html="data.chatTitle || data.friendName || data.groupName"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchImg",
  inject: ["control"],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  computed: {
    list() {
      return this.control.searchData.imgs.map((el) => {
        return this.formartImageUrl(el.url);
      });
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="stylus" scoped>
.search--img
  width: 24%;
  margin: 0.25rem 0.5%;
  .search--img__info
    .value
      font-size: 14px;
      font-weight: bold;
      color: #222222;
</style>
<style lang="stylus">
@import '~styles/utils/index';

    +b(search--img){
      position relative
      +e(item){
        width 100%;
        height 160px;
        overflow hidden
        display flex
        align-items center
        justify-content center
        background #f5f5f5
        .image-slot{
          i{
            font-size 36px
            color #e5e5e5
          }
        }
      }
      +e(info){
        padding .5rem 0
        display flex;
        flex-flow column
        .value{
          font-size 14px;
          color #666666
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
        }
        .name{
          font-size 12px
          color #999999
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          cursor text !important
        }
      }
    }
</style>
