<template>
  <div class="search--msg" @click="$emit('click', data)">
    <div class="search--msg__info">
      <el-avatar v-if="filterData.isUser" :size="36" @error="true">
        <img v-if="filterData.avatar" :src="filterData.avatar" />
        <i v-else class="el-icon-user-solid"></i>
      </el-avatar>
      <span
        class="search--msg__info--title"
        v-if="searchType != 'inner'"
        v-html="filterData.name"
        :title="clearHtml(filterData.name)"
      ></span>
      <span
        class="search--msg__info--time"
        v-html="parseTime(filterData.timeStamp)"
      ></span>
    </div>
    <div class="search--msg__text">
      <span
        class="search--msg__text--user"
        v-if="filterData.sourceSenderName"
        :title="filterData.sourceSenderName"
        v-html="filterData.sourceSenderName + ':'"
      ></span>
      <span
        class="search--msg__text--value"
        v-if="filterData.value"
        v-html="handleValue(filterData.value)"
      ></span>
    </div>
  </div>
</template>
<script>
import { parseTime } from "@/utils";
export default {
  name: "SearchMsg",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    searchType: {
      type: String,
      default: "default",
    },
  },
  computed: {
    filterData() {
      let msg = {};
      const {
        groupId,
        groupName,
        groupOwner,
        groupType,
        friendId,
        friendName,
        friendPic,
        sourceSenderName,
        elasticsearchType,
        value,
        timeStamp,
        msgId,
        chatTitle,
        from,
      } = this.data;
      if (groupId || from.indexOf("G") == 0) {
        msg = {
          id: groupId,
          name: chatTitle || groupName || friendName,
          owner: groupOwner,
          type: groupType,
          isGroup: elasticsearchType === "melinked_group",
          fromUser: {
            id: groupId,
            name: chatTitle || groupName || friendName,
            avatar: "",
          },
        };
      } else if (friendId || from.indexOf("G") === -1) {
        msg = {
          id: friendId,
          name: chatTitle || groupName || friendName,
          avatar: friendPic,
          isUser: elasticsearchType === "melinked_friend",
          fromUser: {
            id: friendId,
            name: chatTitle || groupName || friendName,
            avatar: friendPic,
          },
        };
      }
      let common = {
        sourceSenderName,
        value,
        timeStamp,
        msgId,
      };
      return Object.assign({}, common, msg);
    },
  },
  methods: {
    parseTime,
    handleValue(value) {
      try {
        if (value.indexOf("{") != 0) {
          throw new Error();
        }
        const qs = JSON.parse(value);
        return qs.welcomes;
      } catch (e) {
        return value;
      }
    },
    clearHtml(text) {
      let titleHref = text;
      try {
        titleHref = titleHref
          .replace(new RegExp("<font color=red>", "g"), "")
          .replaceAll(new RegExp("</font>", "g"), "");
      } catch (error) {}
      return titleHref;
    },
  },
};
</script>
<style lang="stylus" scoped>
.search--msg
  width: 48%;
  margin: 0 1% 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  .search--msg__info
    display: flex;
    align-items: center;
    .el-avatar
      margin-right: 0.5rem;
    .search--msg__info--title
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      color: #222222;
    .search--msg__info--time
      font-size: 12px;
      color: #999;
  .search--msg__text
    font-size: 12px;
    margin: 0.2rem 0 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-flow: row;
    .search--msg__text--user
      text-decoration: underline;
      margin-right: 0.25rem;
      cursor: pointer;
      display: inline-block;
    .search--msg__text--value
      color: #888888;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
</style>
<style lang="stylus">
@import '~styles/utils/index';
  +b(search--msg){
    +e(info){
      +m(title){
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }
    }
  }
</style>
