var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search--msg",
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.data)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "search--msg__info" },
        [
          _vm.filterData.isUser
            ? _c("el-avatar", { attrs: { size: 36 }, on: { error: true } }, [
                _vm.filterData.avatar
                  ? _c("img", { attrs: { src: _vm.filterData.avatar } })
                  : _c("i", { staticClass: "el-icon-user-solid" })
              ])
            : _vm._e(),
          _vm.searchType != "inner"
            ? _c("span", {
                staticClass: "search--msg__info--title",
                attrs: { title: _vm.clearHtml(_vm.filterData.name) },
                domProps: { innerHTML: _vm._s(_vm.filterData.name) }
              })
            : _vm._e(),
          _c("span", {
            staticClass: "search--msg__info--time",
            domProps: {
              innerHTML: _vm._s(_vm.parseTime(_vm.filterData.timeStamp))
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "search--msg__text" }, [
        _vm.filterData.sourceSenderName
          ? _c("span", {
              staticClass: "search--msg__text--user",
              attrs: { title: _vm.filterData.sourceSenderName },
              domProps: {
                innerHTML: _vm._s(_vm.filterData.sourceSenderName + ":")
              }
            })
          : _vm._e(),
        _vm.filterData.value
          ? _c("span", {
              staticClass: "search--msg__text--value",
              domProps: {
                innerHTML: _vm._s(_vm.handleValue(_vm.filterData.value))
              }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }