var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search--img",
      on: {
        contextmenu: function($event) {
          $event.preventDefault()
          return _vm.$emit("contextmenuClick", $event, _vm.data)
        }
      }
    },
    [
      _c(
        "el-image",
        {
          staticClass: "search--img__item",
          attrs: {
            src: _vm.formartImageUrl(_vm.data.url),
            fit: "cover",
            "preview-src-list": _vm.list,
            lazy: ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "image-slot",
              attrs: { slot: "error" },
              slot: "error"
            },
            [_c("i", { staticClass: "el-icon-picture-outline" })]
          )
        ]
      ),
      _c("div", { staticClass: "search--img__info" }, [
        _c("span", {
          staticClass: "value",
          domProps: { innerHTML: _vm._s(_vm.data.value) }
        }),
        _c("span", {
          staticClass: "name",
          domProps: {
            innerHTML: _vm._s(
              _vm.data.chatTitle || _vm.data.friendName || _vm.data.groupName
            )
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }