<template>
  <div
    class="search--file"
    @contextmenu.prevent="$emit('contextmenuClick', $event, data)"
    @click="downloadFile(data)"
  >
    <div class="search--file__info">
      <el-image class="thumb" :src="filterData.icon">
        <div slot="error" class="image-slot">
          <i class="el-icon-folder folder">
            <b>{{ filterData.fileSuffix.slice(0, 3) }}</b>
          </i>
        </div>
      </el-image>
      <!-- <i v-else class="el-icon-folder"></i> -->
      <div class="search--file__info--right">
        <div class="search--file__info--base">
          <span
            class="search--file__info--title"
            v-html="filterData.value"
          ></span>
          <span
            class="search--file__info--time"
            v-html="parseTime(filterData.timeStamp)"
          ></span>
        </div>
        <div class="search--file__text">
          <span
            class="search--file__text--user"
            v-if="filterData.lastMessageUserName"
            v-html="filterData.lastMessageUserName + ':'"
          ></span>
          <span
            class="search--file__text--value"
            v-if="filterData.name"
            v-html="filterData.name"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { parseTime, env } from "@/utils";
export default {
  name: "Searchfile",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    filterData() {
      let file = {};
      const {
        groupId,
        groupName,
        groupOwner,
        groupType,
        friendId,
        friendName,
        friendPic,
        lastMessageUserName,
        elasticsearchType,
        value,
        timeStamp,
        chatTitle,
        fileId,
        from,
        fileSuffix,
      } = this.data;
      if (groupId || from.indexOf("G") == 0) {
        file = {
          id: groupId,
          name: chatTitle || groupName,
          owner: groupOwner,
          type: groupType,
          isGroup: elasticsearchType === "melinked_group",
        };
      } else if (friendId || from.indexOf("G") === -1) {
        file = {
          id: friendId || from,
          name: chatTitle || friendName,
          avatar: friendPic,
          isUser: elasticsearchType === "melinked_friend",
        };
      }
      let common = {
        lastMessageUserName,
        value,
        timeStamp,
        fileId,
        fileSuffix,
        icon: this.formartfileIcon(fileSuffix),
      };

      return Object.assign({}, common, file);
    },
  },
  methods: {
    parseTime,
    downloadFile(data) {
      const url = `${env(
        "BASEURL"
      )}/rest/user/download?downloadUrl=${this.formartImageUrl(
        data.url
      )}&fileName=${data.value.replace(/<\/?.+?\/?>/g, "")}`;
      this.$emit("click", url);
    },
  },
};
</script>
<style lang="stylus" scoped>
.search--file
  width: 48%;
  margin: 0 1% 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  .search--file__info
    display: flex;
    align-items: center;
    .search--file__info--right
      flex: 1;
      max-width: 87%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    .el-avatar
      margin-right: 0.5rem;
    .search--file__info--title
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      color: #222222;
    .search--file__info--time
      font-size: 12px;
      color: #999;
  .search--file__text
    font-size: 12px;
    margin: 0.2rem 0 0.5rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-flow: row;
    .search--file__text--user
      text-decoration: underline;
      margin-right: 0.25rem;
      cursor: pointer;
      display: inline-block;
    .search--file__text--value
      color: #888888;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
</style>
<style lang="stylus">
@import '~styles/utils/index';
  +b(search--file){

    +e(info){
      +m(base){
        flex 1
        display flex
        justify-content space-between
      }
      +m(title){
        flex 1
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      }
      +m(time){
      }
      .thumb{
        width 46px
        height 46px
        min-width 46px
        min-height 46px
        object-fit cover
        margin-right .5rem
        display flex
        align-items center
        justify-content center
        background #f5f5f5
        border-radius .3rem
        .image-slot{
          background #e4f0fc
          width 100%
          height 100%
        }
        i{
          font-size 22px
          color #e5e5e5
          position relative
          display flex
          align-items center;
          justify-content center
          &.folder{
            color #1a92ed
            font-size 12px
            width 100%;
            height 100%
            &:before{
              position absolute;
              top 9%
              left 8%;
              font-size 36px
            }
          }
        }
      }
    }
  }
</style>
