var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search--msg",
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.data)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "search--msg__info" },
        [
          _c("el-image", {
            staticStyle: {
              width: "18px",
              height: "18px",
              "margin-right": "10px"
            },
            attrs: { src: _vm.filterData.icon, fit: "cover" }
          }),
          _c("span", {
            staticClass: "search--msg__info--title",
            attrs: { title: _vm.clearHtml(_vm.filterData.subject) },
            domProps: { innerHTML: _vm._s(_vm.filterData.subject) }
          }),
          _c("span", {
            staticClass: "search--msg__info--time",
            domProps: {
              innerHTML: _vm._s(_vm.parseTime(_vm.filterData.sendTimestamp))
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "search--msg__text" }, [
        _vm.filterData.sourceEmail
          ? _c("span", {
              staticClass: "search--msg__text--user",
              attrs: { title: _vm.filterData.sourceEmail },
              domProps: { innerHTML: _vm._s(_vm.filterData.sourceEmail + ":") }
            })
          : _vm._e(),
        !!_vm.filterData.content
          ? _c("span", {
              staticClass: "search--msg__text--value",
              domProps: {
                innerHTML: _vm._s(_vm.handleValue(_vm.filterData.content))
              }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }