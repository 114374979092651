<template>
  <div class="imelink-search" @click="showContextMenu = false">
    <div class="imelink-search__content">
      <el-pagination
        v-if="total"
        class="imelink-search__pagination"
        background
        :current-page.sync="curPage"
        :page-size="pageSize"
        :total="total"
        layout="prev, pager, next"
        @current-change="handlerPageChage"
      >
      </el-pagination>
      <el-tabs v-model="activeTab">
        <el-tab-pane name="msg">
          <i slot="label" class="el-icon--search-msg"></i>
          <div class="el-tabs__content--box" ref="msg">
            <template v-if="showHistory_msg">
              <SearchHistory
                :type="searchType"
                @selectSearchKey="selectSearchKey"
              ></SearchHistory>
            </template>
            <template v-else>
              <template v-if="listData.msgs.length">
                <searchMsg
                  v-for="(item, index) in listData.msgs"
                  :data="item"
                  :key="index"
                  @contextmenuClick="handlerShowMenu"
                  @click="handlerClickMsg"
                  :searchType="searchType"
                />
              </template>
              <div
                class="no-data"
                v-if="!showLoading && listData.msgs.length === 0"
              >
                <img src="../../../assets/ico/empty_data.png" />
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane name="email" v-if="searchType == 'default'">
          <img
            slot="label"
            :style="{
              width: '24px',
              height: '24px',
            }"
            :src="
              activeTab == 'email'
                ? require('../../../assets/images/im/search/tab_email_active.png')
                : require('../../../assets/images/im/search/tab_email.png')
            "
          />
          <div class="el-tabs__content--box" ref="email">
            <template v-if="showHistory_email">
              <SearchHistory
                :type="searchType"
                @selectSearchKey="selectSearchKey"
              ></SearchHistory>
            </template>
            <template v-else>
              <template v-if="displayedEmailQueue.length != 0">
                <searchEmail
                  v-for="item in displayedEmailQueue"
                  :data="item"
                  :key="item.uniqueIdentifier"
                  @click="handlerClickMsg"
                  :searchKey="cloneSearchValue"
                />
              </template>
              <div class="no-data" v-else>
                <img src="../../../assets/ico/empty_data.png" />
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane name="img"
          ><i slot="label" class="el-icon--search-img"></i>
          <div class="el-tabs__content--box" ref="img">
            <template v-if="showHistory_img">
              <SearchHistory
                :type="searchType"
                @selectSearchKey="selectSearchKey"
              ></SearchHistory>
            </template>
            <template v-else>
              <template v-if="listData.imgs.length">
                <searchImg
                  v-for="(message, index) in listData.imgs"
                  :data="message"
                  :key="index"
                  @contextmenuClick="handlerShowMenu"
                />
              </template>
              <div class="no-data" v-else>
                <img src="../../../assets/ico/empty_data.png" />
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane name="video">
          <img
            slot="label"
            :style="{
              width: '24px',
              height: '24px',
            }"
            :src="
              activeTab == 'video'
                ? require('../../../assets/images/im/search/tab_video_active.png')
                : require('../../../assets/images/im/search/tab_video.png')
            "
          />
          <div class="el-tabs__content--box" ref="video">
            <template v-if="showHistory_video">
              <SearchHistory
                :type="searchType"
                @selectSearchKey="selectSearchKey"
              ></SearchHistory>
            </template>
            <template v-else>
              <template v-if="listData.videos.length">
                <searchVideo
                  v-for="(item, index) in listData.videos"
                  :data="item"
                  :key="index"
                  @contextmenuClick="handlerShowMenu"
                />
              </template>
              <div class="no-data" v-else>
                <img src="../../../assets/ico/empty_data.png" />
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane name="file">
          <i slot="label" class="el-icon--search-file"></i>
          <template v-if="showHistory_file">
            <div :style="{ padding: '0.9rem 0.9rem 3rem 0.9rem' }">
              <SearchHistory
                :type="searchType"
                @selectSearchKey="selectSearchKey"
              ></SearchHistory>
            </div>
          </template>
          <div v-else class="files">
            <div class="files__types">
              <span
                v-for="(item, index) in fileTypes"
                :key="index"
                :class="{ subType: true, active: curType === item }"
                @click="curType === item ? (curType = '') : (curType = item)"
                >{{ item }}
              </span>
            </div>
            <div class="el-tabs__content--box files" ref="file">
              <template v-if="listData.files.length">
                <searchFile
                  v-for="(item, index) in listData.files"
                  :data="item"
                  :key="index"
                  @contextmenuClick="handlerShowMenu"
                  @click="downloadFile"
                />
              </template>
              <div class="no-data" v-else>
                <img src="../../../assets/ico/empty_data.png" />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          name="resume"
          v-if="
            $store.getters.userInfo.hasYcResumeGroup && searchType != 'inner'
          "
        >
          <i slot="label" class="el-icon--resume"></i>
          <template v-if="showHistory_resume">
            <div :style="{ padding: '0.9rem 0.9rem 3rem 0.9rem' }">
              <SearchHistory
                :type="searchType"
                @selectSearchKey="selectSearchKey"
              ></SearchHistory>
            </div>
          </template>
          <div v-else class="el-tabs__content--box resumeBox">
            <el-table
              :data="listData.resumes"
              border
              style="width: 100%; height: 100%"
              class="resume-table"
              ref="resume"
              :default-sort="{ prop: 'date', order: 'descending' }"
              @row-click="handlerRowClick"
            >
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column prop="tags" label="标签"> </el-table-column>
              <el-table-column prop="degree" label="学历"> </el-table-column>
              <el-table-column prop="job" label="职位"> </el-table-column>
              <el-table-column prop="source" label="简历来源">
              </el-table-column>
              <el-table-column
                prop="date"
                sortable
                label="上传时间"
                width="160px"
              ></el-table-column>
              <el-table-column prop="uploader" label="上传人">
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <div class="loadingCover" v-if="showLoading && !showHistory">
          <i class="el-icon-loading"></i>
        </div>
      </el-tabs>
    </div>
    <ul v-if="showContextMenu" class="contextMenu-box" :style="style">
      <li v-for="(menu, index) in contextMenu" :key="index" @click="menu.click">
        <i :class="menu.icon"></i>
      </li>
    </ul>
  </div>
</template>
<script>
import searchMsg from "./search/chatmsg";
import searchFile from "./search/file";
import searchImg from "./search/img";
import { downloadFile, env } from "@/utils";
import searchVideo from "./search/video";
import searchEmail from "./search/email";
import * as IMApi from "@/api/im";
import SearchHistory from "./components/searchHistory";
import { searchIndexedDBMailList } from "@/api/indexDB/api/email_api";
export default {
  name: "SearchList",
  inject: ["control"],
  props: {
    searchParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    searchType: {
      type: String,
      default: "default",
    },
    defaultFocus: {
      type: String,
      default: "msg",
    },
  },
  components: {
    searchMsg,
    searchImg,
    searchFile,
    searchVideo,
    SearchHistory,
    searchEmail,
  },
  data() {
    this.icons = {
      msg: "el-icon-search-msg",
      img: "el-icon-search-img",
      video: "el-icon--search-video",
      file: "el-icon-search-file",
      resume: "el-icon--resume",
    };
    return {
      // 被选中的历史搜索关键词
      selectedKeywords: "",
      activeCollapseName: Object.keys(this.icons),
      clickCount: 0,
      contextData: {},
      fileTypes: ["zip", "txt", "pdf", "doc", "xls", "ppt", "..."],
      listData: {
        msgs: [],
        imgs: [],
        videos: [],
        files: [],
        resumes: [],
      },
      localEmails: [],
      displayedEmailQueue: [],
      curType: "",
      activeTab: "",
      types: "",
      suffixType: 1,
      showContextMenu: false,
      contextMenu: [],
      left: -50,
      top: -50,
      total: 0,
      curPage: 1,
      showLoading: false,
      showHistory_msg: false,
      showHistory_email: false,
      showHistory_img: false,
      showHistory_video: false,
      showHistory_file: false,
      showHistory_resume: false,
      cloneSearchValue: "",
    };
  },
  computed: {
    showHistory() {
      return (
        this.showHistory_msg ||
        this.showHistory_email ||
        this.showHistory_img ||
        this.showHistory_video ||
        this.showHistory_file ||
        this.showHistory_resume
      );
    },
    style() {
      return {
        left: this.left + "px",
        top: this.top + "px",
      };
    },
    allCollectIds() {
      return this.$store.state.imStore.allCollects
        ? this.$store.state.imStore.allCollects.map((v) => v.nodeData)
        : [];
    },
    pageSize() {
      let size = 50;
      if (
        (this.$store.state.imStore.IMWindowSize === "medium" ||
          this.$store.state.imStore.IMWindowSize === "mini") &&
        (this.activeTab === "img" || this.activeTab === "video")
      ) {
        size = 52;
      } else if (
        this.$store.state.imStore.IMWindowSize === "full" &&
        (this.activeTab === "img" || this.activeTab === "video")
      ) {
        size = 54;
      } else if (
        this.$store.state.imStore.IMWindowSize === "full" &&
        (this.activeTab === "msg" ||
          this.activeTab === "file" ||
          this.activeTab === "email")
      ) {
        size = 51;
      }
      return size;
    },
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
  },
  watch: {
    "$store.state.imStore.showSearchHistory": {
      handler(newly, old) {
        if (newly != old && this.searchParams.openSearch) {
          this.showHistory_msg = false;
          this.showHistory_email = false;
          this.showHistory_img = false;
          this.showHistory_video = false;
          this.showHistory_file = false;
          this.showHistory_resume = false;
          this[`showHistory_${this.activeTab}`] = newly;
        }
      },
      deep: true,
    },
    "searchParams.openSearch": {
      async handler(newly, old) {
        if (newly && newly != old) {
          if (this.defaultFocus == "email") {
            this.activeTab = "email";
            this.cloneSearchValue = "";
          } else {
            this.activeTab = "msg";
          }
        } else if (!newly && newly != old) {
          this.activeTab = "";
          this.total = 0;
          this.control.searchValue = "";
          this.localEmails = [];
          this.displayedEmailQueue = [];
        }
      },
      deep: true,
    },
    "searchParams.listData": {
      handler(newly, old) {
        this.listData = newly;
        try {
          this.$refs[this.activeTab].$forceUpdate();
        } catch (e) {}
        if (newly.resumes.length) {
          this.$refs.resume.$forceUpdate();
        }
      },
      deep: true,
    },
    "searchParams.total": {
      handler(newly, old) {
        this.total = newly;
        try {
          this.$refs[this.activeTab].$forceUpdate();
        } catch (e) {}
      },
      deep: true,
    },
    curType: {
      handler(val, old) {
        if (val && val != old) {
          let subType = "";
          let suffixType = 1;
          switch (val) {
            case "doc":
              subType = "doc,docx";
              break;
            case "xls":
              subType = "xls,xlsx";
              break;
            case "ppt":
              subType = "ppt,pptx";
              break;
            case "zip":
              subType = "zip,rar";
              break;
            case "...":
              subType = _.cloneDeep(this.fileTypes)
                .concat(["docx", "xlsx", "pptx", "rar"])
                .map((el) => {
                  if (el !== "...") return el;
                })
                .join(",");
              suffixType = 0;
              break;

            default:
              subType = val;
              break;
          }
          this.types = subType;
          this.suffixType = suffixType;
          this.handlerEmitRequestSearch(this.types, null, this.suffixType);
        }
      },
      deep: true,
    },
    activeTab: {
      handler(val) {
        if (val == "") {
          return;
        }
        if (this.searchType == "main") {
          this.control.catchSearchCurrentTab = val;
        }
        if (this.curType) {
          this.curType = "";
        }
        this.types = "";
        if (val == "email") {
          this.getAllLocalEmails();
        } else {
          this.handlerEmitRequestSearch();
          if (val !== "msg" && this.contextMenu.length === 0) {
            this.contextMenu = [
              {
                icon: "el-icon-download",
                click: () => {
                  let url = `${env(
                    "BASEURL"
                  )}/rest/user/download?downloadUrl=${this.formartImageUrl(
                    this.contextData.url
                  )}&fileName=${this.contextData.value.replace(
                    /<\/?.+?\/?>/g,
                    ""
                  )}`;
                  downloadFile(url);
                },
              },
              {
                icon: "el-icon--chat",
                click: () => this.handlerChatTo(),
              },
            ];
          } else if (val === "msg") {
            this.contextMenu = [];
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 拉取所有本地邮件
    async getAllLocalEmails() {
      this.showLoading = true;
      // 更新已绑定邮箱列表
      await this.globalGetBindEmailList();
      await this.handlerDisplayedEmailQueue(1, this.control.searchValue);
      this.showLoading = false;
    },
    async handlerDisplayedEmailQueue(nowPage = 1, searchValue = "") {
      this.cloneSearchValue = searchValue.trim();
      this.showLoading = true;
      let mailTokens = [];
      for (let i = 0; i < this.emailAccounts.length; i++) {
        let item = this.emailAccounts[i];
        try {
          let password = await this.globalVerifyEmailPassword(item, false);
          mailTokens.push({
            u: item.mailAddress,
            p: password,
          });
        } catch (error) {}
      }
      if (mailTokens.length == 0) {
        this.$message({
          type: "error",
          message: this.$t("emailSearchTips"),
        });
        return;
      }
      const result = await searchIndexedDBMailList({
        page: nowPage,
        terms: searchValue.trim(),
        pageSize: this.pageSize,
        mailTokens,
      });
      if (result.code == 200) {
        this.displayedEmailQueue = result.data.data;
        this.total = parseInt(result.data.total);
      } else {
        this.$message.error(result.message);
      }
      this.showLoading = false;
    },
    handlerRowClick(row, column, event) {
      this.jumpPage({ name: "resume", params: { rid: row.id } });
    },
    downloadFile,
    handlerPageChage(page) {
      let newpage = page - 1 < 0 ? 0 : page - 1;
      if (this.activeTab === "resume") {
        newpage = page;
      }
      if (this.activeTab !== "email") {
        this.handlerEmitRequestSearch(this.types, newpage, this.suffixType);
      } else {
        this.handlerDisplayedEmailQueue(page, this.control.searchValue);
      }
      this.$nextTick(() => {
        this.$refs[this.activeTab].scrollTop = 10;
      });
    },
    // 点击搜索选项
    async handlerChatTo() {
      this.showContextMenu = false;
      this.$emit("closeSearchMode");
      this.$emit("setCatchSearchWord");
      let params = null;
      if (
        this.control.records.length > 0 &&
        this.control.records.some((v) => v.id == this.contextData.fromUser.id)
      ) {
        const findItem = this.control.records.find((v) => {
          return v.id == this.contextData.fromUser.id;
        });
        params = {
          ...findItem,
          isSearch: true,
          messageId: this.contextData.msgId,
        };
      } else {
        params = {
          id: this.contextData.fromUser.id,
          avatar: this.contextData.fromUser.avatar,
          displayName: this.contextData.fromUser.name.replace(
            /<\/?.+?\/?>/g,
            ""
          ),
          lastMessageTime: this.contextData.timeStamp,
          lastMessageType: this.contextData.type,
          isSearch: true,
          messageId: this.contextData.msgId,
          collected: this.allCollectIds.some(
            (v) => v == this.contextData.fromUser.id
          ),
        };
        if (this.contextData.isUser) {
          params.isUser = true;
        }
        if (this.contextData.isGroup) {
          params.isGroup = true;
          const req = await IMApi.queryGroupInfoAndMembers(params.id);
          if (req.data) {
            params.avatar = req.data[0].group_header;
            params.displayName = req.data[0].group_name;
          }
        }
      }
      if (params.id == 2) {
        await this.$openMechat(this.$store.state.imStore.IMWindowSize);
      } else {
        await this.$openIM(params, this.$store.state.imStore.IMWindowSize);
      }
    },
    handlerShowMenu(event, data) {
      this.showContextMenu = true;
      this.left = event.clientX;
      this.top = event.clientY;
      this.contextData = this.filterData(data);
    },
    handlerClickMsg(data) {
      // 清空搜索项
      document.getElementById("search-input").value = "";
      if (this.activeTab == "email") {
        this.$store.commit("setNeedOpenEmailAddress", {
          mailAddress: data.userName,
          folder_name: data.mailboxName,
        });
        data.uniqueIdentifier = `${data.userName}/${data.mailboxName}/${data.uid}`;
        this.$store.commit("setAnchorMail", data);
        if (this.control.currentTab == "email") {
          this.$emit("closeSearchMode");
          this.$emit("setCatchSearchWord");
        } else {
          // 延时等待IM区域加载
          setTimeout(() => {
            this.$openEmail();
          }, 500);
        }
      } else {
        this.contextData = this.filterData(data);
        setTimeout(() => {
          this.$emit("isClear", "cc12345");
        }, 1000);
        this.handlerChatTo();
      }
    },
    filterData(data) {
      let msg = {};
      const {
        groupId,
        groupName,
        groupOwner,
        groupType,
        friendId,
        friendName,
        friendPic,
        lastMessageUserName,
        value,
        timeStamp,
        chatTitle,
        msgId,
        from,
        type,
        url,
      } = data;
      if (groupId || from.indexOf("G") == 0) {
        msg = {
          id: groupId,
          name: chatTitle || groupName,
          owner: groupOwner,
          type: groupType,
          timeStamp,
          isGroup: groupId || from.indexOf("G") === 0,
          fromUser: {
            id: groupId || from.replace("G", ""),
            name: chatTitle || groupName,
            avatar: "",
          },
        };
      } else if (friendId || from.indexOf("G") === -1) {
        msg = {
          id: friendId || from,
          name: chatTitle || friendName,
          avatar: friendPic,
          timeStamp,
          fromUser: {
            id: friendId || from,
            name: chatTitle || friendName,
            avatar: friendPic,
          },
          isUser: friendId || from.indexOf("G") === -1,
        };
      }
      let common = {
        lastMessageUserName,
        value,
        timeStamp,
        msgId,
        type,
        url,
      };

      return Object.assign({}, common, msg);
    },
    selectSearchKey(key) {
      if (this.activeTab == "email") {
        this.control.searchValue = key;
        this.handlerDisplayedEmailQueue(1, key);
      } else {
        this.selectedKeywords = key;
        this.handlerEmitRequestSearch();
      }
    },
    handlerEmitRequestSearch(subType = this.types, page = 0, suffixType = 1) {
      if (this.activeTab == "") {
        return;
      }
      if (this.activeTab == "email") {
        return;
      }
      this.showLoading = true;
      const event = {
        activeTab: this.activeTab,
        key:
          this.activeTab === "msg"
            ? "search"
            : this.activeTab === "img"
            ? "image"
            : this.activeTab,
        pageNum: page,
        pageSize: this.pageSize,
        changeTab: true,
        suffixType,
        subType,
        successCallback: () => {
          if (this.curPage != page + 1) {
            this.curPage = page + 1;
          }
          try {
            this.$refs[this.activeTab].scrollTop = 10;
          } catch (error) {}
          this.showLoading = false;
        },
        errorCallback: (message) => {
          this.showLoading = false;
          console.log("error:", message);
        },
      };
      if (this.selectedKeywords != "") {
        event.selectedKeywords = this.selectedKeywords;
        this.selectedKeywords = "";
      }
      this.$emit("handlerSearch", event);
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

.resume-table {
  .el-table__body-wrapper {
    margin-top: 45px;

    .el-table__body {
      .el-table__row {
        cursor: pointer;
      }
    }
  }

  td {
    padding: 7px 0;
  }

  th {
    padding: 4px 0;
    color: #333333;
    font-weight: bold;
    background: #eee;
    border-top: 1px solid #ffffff;
  }
}

.el-image-viewer__close {
  color: #ffffff;
}

.el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li {
  background: #ffffff;
  min-width: 24px;
}

+b(imelink-search) {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  +e(pagination) {
    position: absolute;
    right: 0;
    top: 15px;
    z-index: 2;
    margin: 0 !important;

    .btn-prev, .btn-next, .el-pager li {
      width: auto !important;
      height: 24px !important;
      line-height: 24px !important;
      border-radius: 5rem !important;
    }
  }

  .contextMenu-box {
    position: fixed;
    background: #ffffff;
    box-shadow: 0 0 3px 3px rgba(102, 102, 102, 0.3);
    z-index: 5;
    border-radius: 0.3rem;

    // padding 0 .5rem
    li {
      padding: 0.5rem 0.6rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #efefef;

      i {
        font-size: 20px;

        &.el-icon--chat {
          color: #33cc66;
        }
      }
    }
  }

  +e(more) {
    text-align: center;
    font-size: 20px;
    color: #52bd68;
    cursor: pointer;
  }

  +e(nav) {
    // flex-center();
    background: rgba(238, 238, 238, 1);

    // padding: 30px;
    // justify-content: space-between;
    .el-button {
      font-size: 18px;
    }
  }

  .files {
    +e(types) {
      padding: 0.5rem 0.9rem;
      margin: 0.3rem 0 0;

      span {
        border-radius: 5rem;
        border: 1px solid #e5e5e5;
        padding: 0.3rem 0.9rem;
        font-size: 13px;
        color: #666666;
        margin: 0 0.25rem;
        cursor: pointer;

        &.active {
          color: #33cc66;
          border-color: #33cc66;

          &::after {
            font-family: 'icons';
            content: '\e68b';
          }
        }
      }
    }
  }

  +e(content) {
    height: 100%;

    .el-tabs {
      .el-tabs__header {
        background: rgba(238, 238, 238, 1);
        margin: 0;

        .el-tabs__nav-wrap {
          padding: 0.3rem 0.9rem;

          &:after {
            background: rgba(238, 238, 238, 1);
          }

          .el-tabs__nav-scroll {
            .el-tabs__nav {
              .el-tabs__item {
                height: auto;
                line-height: normal;
                padding: 0.5rem 0.9rem;

                &.is-active {
                  i {
                    color: #33cc66;
                  }
                }
              }
            }
          }
        }
      }

      +e(content) {
        +m(box) {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          padding: 0.9rem 0.9rem 3rem 0.9rem;
          max-height: 470px;
          scrollbar-light();
          overflow-y: scroll;

          &.resumeBox {
            padding: 0;
            position: relative;

            .resume-table {
              .el-table__header-wrapper {
                position: absolute;
                top: 0;
                z-index: 5;
              }
            }
          }

          &.files {
            padding-bottom: 6rem;
          }

          .no-data {
            width: 100%;
            text-align: center;
            padding: 1.9rem 0;
            border-top: 0;

            img {
              width: 80px;
              object-fit: cover;
            }
          }

          .el-loading-mask {
            min-height: 480px;
          }

          // justify-content space-around;
          .search {
            +m(msg) {
              width: 48%;
              margin: 0 1% 0.5rem;
              padding-bottom: 0.5rem;
              border-bottom: 1px solid #f3f3f3;
              cursor: pointer;

              +e(info) {
                display: flex;
                align-items: center;

                .el-avatar {
                  margin-right: 0.5rem;
                }

                +m(title) {
                  flex: 1;
                  font-size: 14px;
                  font-weight: bold;
                  color: #222222;
                }

                +m(time) {
                  font-size: 12px;
                  color: #999;
                }
              }

              +e(text) {
                font-size: 12px;
                margin: 0.2rem 0 0.5rem 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                flex-flow: row;

                +m(user) {
                  text-decoration: underline;
                  margin-right: 0.25rem;
                  cursor: pointer;
                  display: inline-block;
                  // padding-bottom .25rem
                }

                +m(value) {
                  color: #888888;
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }

            +m(img) {
              width: 24%;
              margin: 0.25rem 0.5%;

              +e(info) {
                .value {
                  font-size: 14px;
                  font-weight: bold;
                  color: #222222;
                }
              }
            }

            +m(video) {
              width: 24%;
              margin: 0 0.5%;

              +e(info) {
                .value {
                  font-size: 14px;
                  font-weight: bold;
                  color: #222222;
                }
              }
            }

            +m(file) {
              width: 48%;
              margin: 0 1% 0.5rem;
              padding-bottom: 0.5rem;
              border-bottom: 1px solid #f3f3f3;
              cursor: pointer;

              +e(info) {
                display: flex;
                align-items: center;

                +m(right) {
                  flex: 1;
                  max-width: 87%;
                  display: flex;
                  flex-flow: column;
                  justify-content: space-between;
                }

                .el-avatar {
                  margin-right: 0.5rem;
                }

                +m(title) {
                  flex: 1;
                  font-size: 14px;
                  font-weight: bold;
                  color: #222222;
                }

                +m(time) {
                  font-size: 12px;
                  color: #999;
                }
              }

              +e(text) {
                font-size: 12px;
                margin: 0.2rem 0 0.5rem 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                flex-flow: row;

                +m(user) {
                  text-decoration: underline;
                  margin-right: 0.25rem;
                  cursor: pointer;
                  display: inline-block;
                  // padding-bottom .25rem
                }

                +m(value) {
                  color: #888888;
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }

  i {
    &.el-icon--search-msg, &.el-icon--search-img, &.el-icon--search-video, &.el-icon--search-file, &.el-icon--resume {
      font-size: 26px;
      color: rgba(102, 102, 102, 1);
    }
  }

  .el-collapse {
    border: none;

    .el-collapse-item {
      height: 25%;
    }

    .el-collapse-item__header {
      background: #e9e9e9;
      height: 38px;
      line-height: 38px;
    }

    .el-collapse-item__wrap {
      background: transparent;
      height: 100%;
    }

    .el-collapse-item__content {
      overflow: hidden;
      padding-bottom: 0;
    }
  }

  +e(colico) {
    font-size: 18px;
    padding-left: 12px;
  }
}
</style>

<style lang="sass">
.imelink-search__content
  .loadingCover
    position: absolute
    top: 50%
    left: 50%
    transform: translate3d(-50%, -50%, 0)
    z-index: 10
    .el-icon-loading
      color: #52bd68
      font-size: 30px
</style>
