var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search--file",
      on: {
        contextmenu: function($event) {
          $event.preventDefault()
          return _vm.$emit("contextmenuClick", $event, _vm.data)
        },
        click: function($event) {
          return _vm.downloadFile(_vm.data)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "search--file__info" },
        [
          _c(
            "el-image",
            { staticClass: "thumb", attrs: { src: _vm.filterData.icon } },
            [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [
                  _c("i", { staticClass: "el-icon-folder folder" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.filterData.fileSuffix.slice(0, 3)))
                    ])
                  ])
                ]
              )
            ]
          ),
          _c("div", { staticClass: "search--file__info--right" }, [
            _c("div", { staticClass: "search--file__info--base" }, [
              _c("span", {
                staticClass: "search--file__info--title",
                domProps: { innerHTML: _vm._s(_vm.filterData.value) }
              }),
              _c("span", {
                staticClass: "search--file__info--time",
                domProps: {
                  innerHTML: _vm._s(_vm.parseTime(_vm.filterData.timeStamp))
                }
              })
            ]),
            _c("div", { staticClass: "search--file__text" }, [
              _vm.filterData.lastMessageUserName
                ? _c("span", {
                    staticClass: "search--file__text--user",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.filterData.lastMessageUserName + ":"
                      )
                    }
                  })
                : _vm._e(),
              _vm.filterData.name
                ? _c("span", {
                    staticClass: "search--file__text--value",
                    domProps: { innerHTML: _vm._s(_vm.filterData.name) }
                  })
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }