var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "historyView" },
    [
      _c(
        "div",
        {
          staticClass: "titleRow",
          style: {
            "max-width": _vm.type == "default" ? "520px" : "400px"
          }
        },
        [
          _c("i", { staticClass: "iconfont_Me icon-history iconStyle" }),
          _c("i", {
            staticClass: "iconfont_Me icon-delet-fill iconStyle deleteIcon",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clearAll.apply(null, arguments)
              }
            }
          })
        ]
      ),
      _vm._l(_vm.historyCache, function(i, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "commonRow",
            style: {
              "max-width": _vm.type == "default" ? "520px" : "400px"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.selectOne(i)
              }
            }
          },
          [
            _c("span", { staticClass: "keywordStyle" }, [
              _vm._v(_vm._s(i.keyword))
            ]),
            _c("i", {
              staticClass: "iconfont_Me icon-x iconStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.deleteOne(i)
                }
              }
            })
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }